.Logs {
    background: #262A41;
    border-radius: 14px 14px 14px 14px;
    box-shadow: 0 0 0 0 rgba(50,50,93,0.10), 0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07);
    min-height: 375px;
    height: 80vh;
    width: 60vw;
    display: flex;
    flex-direction: column;

}

.Logs-header {
    border-radius: 14px 14px 0 0;
    border-bottom: 1px solid #4E566D;
    padding: 0 16px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.Logs-body {
    border-radius: 0 0 14px 14px;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    justify-content: center;
}

.Logs-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Logs-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
}

.fade-enter {
    opacity: 0.01;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
}
  